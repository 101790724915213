<template>
    <v-card class="pl-0 elevation-0" style="width: 400px;">
    <v-card-title v-if="objTrackingSheet.trs_id" primary-title class="pa-0 mb-2" style="border-bottom:1px solid #E0E0E0;">
        <div v-if="objTrackingSheet.trs_id">
            <h3 class="text-truncate headline mb-0">{{ objTrackingSheet.trs_name }}</h3>
        </div>
        <div v-else>
            <h3 class="text-truncate headline mb-0">Informations</h3>
        </div>
    </v-card-title>
    <v-card-text>
        <v-layout v-if="objTrackingSheet.trs_id" wrap style="background-color:white;" class="pa-0 elevation-0">
            <v-flex xs6 ><div @click="infoJson = !infoJson"> Nom : </div></v-flex>
            <v-flex xs6 class="pl-0"><div class="font-weight-bold">{{ objTrackingSheet.trs_name }}</div></v-flex>
            <v-flex xs6 ><div> Site : </div></v-flex>
            <v-flex xs6 class="pl-0"><div class="font-weight-bold">{{ objTrackingSheet.trs_mc2_code }}</div></v-flex>
            <v-flex xs6 ><div> Type : </div></v-flex>
            <v-flex xs6 >
                <div class="font-weight-bold">
                    <v-chip small outlined label :color="getCacheType('SIT', objTrackingSheet.trs_typ_id).typ_color">{{ getCacheType('SIT', objTrackingSheet.trs_typ_id).typ_name }}</v-chip>
                </div>
            </v-flex>
            <v-flex xs6 ><div> Surface : </div></v-flex>
            <v-flex xs6 ><div class="font-weight-bold">{{ objTrackingSheet.trs_area1 }}</div></v-flex>
            <v-flex xs6 ><div> Commune : </div></v-flex>            
            <v-flex xs6 ><div class="font-weight-bold">{{ objTrackingSheet.tow_name }}</div></v-flex>
            <v-flex xs6 ><div> Distance ligne : </div></v-flex>
            <v-flex xs6 ><div class="font-weight-bold">{{ objTrackingSheet.trs_rail_distance }}</div></v-flex>
            <!-- <v-flex xs6 ><div> Cahier des charges : </div></v-flex>
            <v-flex xs6 ><div class="font-weight-bold"><v-chip x-small outlined label light color="green"><v-icon small>mdi-link-variant-plus</v-icon></v-chip></div></v-flex>
            <v-flex xs6 ><div> Espèces : </div></v-flex>
            <v-flex xs6 ><div class="font-weight-bold"><v-chip x-small outlined label light color="green"><v-icon small>mdi-link-variant-plus</v-icon></v-chip></div></v-flex>
            -->
            <v-flex xs12 style="border-top:1px solid #E0E0E0;">
                <v-layout wrap style="background-color:white;" class="pa-0 elevation-0">
                    <template v-for="(item, index) in spicesGroup">
                        <v-flex xs6 :key="index">{{item.text}}</v-flex>
                        <v-flex xs6 :key="index + '-'">
                            <v-chip x-small outlined label light color="green">{{item.value}}</v-chip>
                        </v-flex>
                    </template>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout v-show="!this.objTrackingSheet.trs_id" wrap style="background-color:white;" class="pa-0 elevation-0">
            <v-layout wrap style="background-color:white;" class="pa-0 elevation-0">
                <template v-for="(prop, index) in popupInfo">
                    <v-flex xs6 :key="index">{{index}} :</v-flex>
                    <v-flex xs6 :key="index + '-'">{{prop}}</v-flex>
                </template>
            </v-layout>
        </v-layout>
    </v-card-text>
    <v-card-actions v-if="objTrackingSheet.trs_id">
        <v-spacer></v-spacer>
        <v-btn rounded outlined depressed text  :href="'/#/map/site/' + this.objTrackingSheet.trs_id" color="primary" class="white--text">
            Voir site {{ (this.objTrackingSheet.trs_coddt) ? '(' + this.objTrackingSheet.trs_coddt + ')' : '' }}
        </v-btn>
        <v-btn :href="'#/trackingsheet/site/' + this.objTrackingSheet.trs_id" target="new" @click.stop small rounded outlined color="primary" dark><v-icon>mdi-application-export</v-icon></v-btn>
        <!-- <v-btn flat color="purple">Explore</v-btn>
        <v-spacer></v-spacer> -->
    </v-card-actions>
    </v-card>
</template>

<script>
import { trsObj }       from '../mixins/trs_obj.js'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'map_popup',
    props: ['popupInfo'],
    mixins: [ trsObj ],
    data: function () {
        return {
            map: null,
            infoJson: false,
            confInfo: {},
            trsStaIdColor: { 
                'SIT01': 'blue', 'SIT02': 'orange', 'SIT03': 'orange', 'SIT04': 'orange', 'SIT05': 'green', 'SIT06': 'grey'
            },
            spicesGroup: []
        }
    },
    watch: {
        popupInfo (val) {
            if( val.trs_id ){
                this.objTrackingSheet.trs_id = this.popupInfo.trs_id
                this.getData()
            } else {
                this.objTrackingSheet.trs_id = 0
            }
        }
    },
    mounted:function (){

    },
//    updated: function(){},
    methods: {
        async getData(){
            this.spicesGroup = []
            await this.getTrackingSheetShow()
            var searchCriteria = '?per_page=false&csp_trs_id=' + this.objTrackingSheet.trs_id
            this.$http.get( '/compensationspecies/bygroup/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        element.spg_name = ( element.spg_name == undefined ) ? 'Inconnu' : element.spg_name
                        this.spicesGroup.push( { text: element.spg_name, value: element.csp_qty })  
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes
    }
}
</script>