<template>
<div style="height: 100%; position: relative; overflow:hidden; z-index:6;">
    <div id="map-container" style="height: 100%;"></div>
    <v-alert ref="liveinfo" :value="liveInfoState" color="primary" dark icon="mdi-information"  
        style="top: 10px; right: 250px; z-index: 1050; position: absolute;">
    {{liveInfo}}
    </v-alert>
    <map-popup-content v-show="true" ref="dialinfo" :popupInfo="popupInfo"></map-popup-content>
    <v-menu bottom left :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip left style="z-index:1051;">
                <template v-slot:activator="{ on: onTooltip }">
                <v-btn fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; z-index:1050;" 
                    elevation-1 v-on="{ ...onMenu, ...onTooltip }">
                    <v-icon>mdi-map-legend</v-icon>
                </v-btn>
                </template>
                <span>Changer le fond de plan</span>
            </v-tooltip>
        </template>
        <v-list dense class="rounded-lg">
            <template v-for="(item, pindex) in tileLayer">
            <v-list-item :key="pindex" @click="changeTileLayer(pindex)">
                <v-list-item-action>
                    <img :src="item.thumbnail" width="65" class="rounded-lg" :style="(item.active) ? 'border: 3px solid orange;' : ''" />
                </v-list-item-action>
                <v-list-item-title :class="(item.active) ? 'font-weight-bold' : ''">{{item.text}}</v-list-item-title>
                <v-list-item-action @click.stop="item.set = !item.set">
                    <v-icon :color="'grey'" style="cursor:pointer;">mdi-palette</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="item.set" :key="'id:' + pindex" @click="changeTileLayer(pindex)">
                <v-slider v-model="item.opacity" @input="setLayerStyle(pindex)" @click.stop></v-slider>
            </v-list-item>
            </template>
        </v-list>
    </v-menu>
    <v-menu v-model="layerMenu" :close-on-content-click="false" bottom offset-x :max-height="550">
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip left style="z-index:1051;">
                <template v-slot:activator="{ on: onTooltip }">
                <v-btn color="primary" v-on="{ ...onMenu, ...onTooltip }" fixed dark fab bottom right style="opacity: .80; top:170px; z-index:1050;">
                    <v-icon>mdi-layers</v-icon>
                    <v-badge v-show="toolBar && activeLayers" :color="(activeLayers == 0) ? 'red' : 'green'"><span slot="badge">{{ activeLayers }}</span></v-badge>
                </v-btn>
                </template>
                <span>Couches SIG</span>
            </v-tooltip>
        </template>
        <v-list>
        <v-list-item v-for="(item, index) in objLayerList" :key="index" >
            <v-list-item-action @click="showHideSigLayer(index)" class="mr-1">
                <v-icon :color="(item.active) ? 'green' : 'gray'" style="cursor:pointer;">mdi-eye</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="selectLayerColor(index)" class="mr-1">
                <v-icon :color="item.lay_color" style="cursor:pointer;">mdi-palette</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="fitTo(index)" class="mr-1">
                <v-icon color="grey" style="cursor:pointer;">mdi-crosshairs-gps</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.lay_name }}</v-list-item-title>
        </v-list-item>
        </v-list>
    </v-menu>
    <v-tooltip left style="z-index:1051;">
        <template v-slot:activator="{ on }">
            <v-btn @click="pdfDiag = true" fixed dark fab bottom right color="primary" style="opacity: .50; top:260px; z-index:1050;" elevation-1 v-on="on">
                <v-icon>mdi-file-export</v-icon>
            </v-btn>
        </template>
        <span>Télécharger la carte</span>
    </v-tooltip>
    <v-tooltip left style="z-index:1051;" v-show="lastSheet.trs_id">
        <template v-slot:activator="{ on }">
            <v-btn :href="'#/map/site/' + lastSheet.trs_id" fixed dark fab bottom right color="green" style="opacity: .60; top:50%; z-index:1050;" elevation-1 v-on="on">
                <v-icon>mdi-folder-open</v-icon>
            </v-btn>
        </template>
        <span>Ouvrir la dernière fiche {{ lastSheet.trs_id }}</span>
    </v-tooltip>

    <!-- <v-btn v-if="drawMode" @click="saveDrawJson" fixed dark bottom small right color="green" style="position: absolute; opacity: .80; left: 10px; top:350px; z-index:1050;" elevation-1>
        <v-icon>mdi-content-save</v-icon>
    </v-btn> -->
    <!-- Dialog export PDF -->
    <v-dialog v-model="pdfDiag" width="500" >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Options du document
        </v-card-title>
        <v-card-text>
            <v-layout row wrap>
            <v-flex xs12><v-text-field v-model="pdfOpt.title" label="Titre"></v-text-field></v-flex>
            <v-flex xs12><v-text-field v-model="pdfOpt.comment" label="Commentaires"></v-text-field></v-flex>
            <v-flex xs6>
            <v-select :items="['logo_lisea', 'logo_dreal']" value="logo_lisea" v-model="pdfOpt.logo" label="Logo" single-line ></v-select>
            </v-flex>
            <v-flex xs6>
            <v-select :items="['Format Pdf', 'Format Shape']" value="Format Pdf" v-model="pdfOpt.type" label="Format d'export" single-line ></v-select>
            </v-flex>
            </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="pdfloading" text @click="getReport()" >Télécharger</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Selection de couleurs -->
    <v-dialog v-if="colorDialog" v-model="colorDialog" hide-overlay max-width="600px" style="z-index:1051;">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Choisir la couleur du calque :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="colorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-color-picker @input="saveLayerColor" :value="pickerColor" class="ma-2 elevation-0"
                hide-inputs hide-canvas mode="hexa" ></v-color-picker> 
        </v-card-text>
    </v-card>
    </v-dialog>
    <div style="min-width:25%; position:absolute; left:60px; top:20px; z-index: 899;">
        <v-autocomplete
        v-model="searchAddress"
        label="Rechercher"
        :items="addressTab"
        item-value="id" item-text="label"
        :search-input.sync="search"
        @input="panTo"
        append-icon="mdi-magnify" filled outlined dense clearable class="rounded-xl" no-filter
        background-color="white" style="opacity: 0.7;">
        </v-autocomplete>
    </div>
</div>
</template>

<script>
//import { store, mutations } from "../store/store"
import LfMap      from '../plugins/map';
import LeafletImg from 'leaflet-image';
import mapPopup   from '../components/Popup_map.vue'
import { setTimeout } from 'timers';
var mapthis = { map: null }
import { trsObj }  from '../mixins/trs_obj.js'
import { cacheGetters } from "../store/cache.module"
//import draggable from "vuedraggable";

export default {
    name: 'site_map',
    props: [],
    mixins: [ trsObj ],
    data: function () {
        return {
            pickerColor : null,
            map: null,
            popupInfo: {},
            botNav: { height: 55 },
            colorDialog: false,
            liveInfo: '',
            liveInfoState: false,
            pdfDiag: false,
            toolBar: true,
            layerMenu: false, objLayerList: {},
            activeLayer: {},//{ sites: { active: true }, lgv: { active: true } },
            slider : 10,
            currentLayer: 0,
            pdfOpt: { title:'', comment:'', logo:'logo_lisea', type:'Format Pdf' },
            pdfloading: false,
            addressTab: [],
            searchAddress: null,
            search: null,
            searchTempo: null,
            timeOutHandle: 'off',
            searchWord: '',
            trackRefCode: {},
            activeTile: 'osm',
            tileLayer: [
                { id: 'osm', text: 'Fond de plan OSM',                 active: 1, set: false, opacity: 100, thumbnail: require('../assets/plan_osm.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + process.env.VUE_APP_IGN_KEY + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                { id: 'ign', text: 'Fond de plan IGN',                 active: 0, set: false, opacity: 100, thumbnail: require('../assets/plan_ign.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + process.env.VUE_APP_IGN_KEY + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                { id: 'bnb', text: 'Fond de plan NB',                  active: 0, set: false, opacity: 100, thumbnail: require('../assets/plan_nb.jpg'),    link: 'https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                { id: 'bph', text: 'Fond de plan photo',               active: 0, set: false, opacity: 100, thumbnail: require('../assets/plan_photo.jpg'), link: 'https://{s}.tiles.technosig.fr/' + process.env.VUE_APP_IGN_KEY + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                { id: 'prc', text: 'Fond cadastre',                    active: 0, set: false, opacity: 100, thumbnail: require('../assets/plan_parcel.jpg'),  link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&style=PCI vecteur&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                { id: 'apb', text: 'Arrêtés de protection de biotope', active: 0, set: false, opacity: 50, thumbnail: require('../assets/plan_biotope.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDSITES.MNHN.RESERVES-REGIONALES&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                { id: 'n2h', text: 'Sites NATURA 2000 (Habitats)',     active: 0, set: false, opacity: 50, thumbnail: require('../assets/plan_habitats.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDAREAS.SIC&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                { id: 'n2o', text: 'Sites NATURA 2000 (Oiseaux)',      active: 0, set: false, opacity: 50, thumbnail: require('../assets/plan_oiseaux.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDAREAS.ZPS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                //{ id: 'zic', text: 'ZICO', active: 0, opacity: 0.5, thumbnail: require('../assets/plan_biotope.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDSITES.MNHN.RESERVES-REGIONALES&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                {id: 'zn1', text: 'ZNIEFF type I',                    active: 0, set: false, opacity: 50, thumbnail: require('../assets/plan_znief1.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDAREAS.ZNIEFF1&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                {id: 'zn2', text: 'ZNIEFF type II',                   active: 0, set: false, opacity: 50, thumbnail: require('../assets/plan_znief2.jpg'), link: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDAREAS.ZNIEFF2&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}', color: 'green', icon: 'mdi-check'},
                //{ id: 'bwt', text: 'Sans fond',                        active: 0, set: false, opacity: 100, thumbnail: require('../assets/plan_sans.jpg'),  link: '', color: 'green', icon: 'mdi-check'},
            ],
            drawMode: false,
            drawActiveId: -1
        }
    },
    watch: {
        filter_agc: function(){
            this.objSite.agc_id = this.filter_agc
            mapthis.map.removeSiteMarker()
        },
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        }
    },
    computed: {
        filter_agc() {
            return true
        },
        activeLayers: function(){
            var totalActive = 0
            for( var prop in this.objLayerList ){
                if( this.objLayerList[prop].active ){
                    totalActive += 1
                }
            }
            return totalActive
        },
        lastSheet(){
            return cacheGetters.getLastSheet()
        }
    },
    beforeMount: function(){
        mapthis = { map: null }
    },
    mounted:function (){
        this.initMap()
    },
    methods: {
        async initMap(){
            mapthis.map = new LfMap('map-container')
            if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
                mapthis.map.mapCenter = this.$route.params.latlng.split(',')
            }
            this.displayMap()
            mapthis.map.updateLiveInfo = this.updateLiveInfo
            mapthis.map.component.popupContent = this.$refs.dialinfo.$el
            mapthis.map.updatePopupInfo = this.updatePopupInfo

            await this.getLayerList()
            this.getTrackingList()
        },
        displayMap() {
            mapthis.map.display()
        },
        updatePopupInfo(id, info){
            info.trs_id = 0
            if( this.objLayerList[id] !== undefined && this.objLayerList[id].lay_field ){
                this.objLayerList[id].lay_field.forEach( elem => {
                    if( elem.infoPopup && info[ elem.source ] && elem.destination == 'trs_mc2_id' ){
                        let code    = info[ elem.source ].split("-")
                        let mc2code = info[ elem.source ]
                        if(code.length > 3){
                            mc2code = code[0] + '-' + code[1] + '-' + code[2] + '-' + code[3] 
                        }
                        if( this.trackRefCode[ mc2code ] ){
                            info.trs_id = this.trackRefCode[ mc2code ].trs_id
                        }
                    }
                })
            }
            this.popupInfo = info
        },
        updateLiveInfo(id, info, state){
            if(state === undefined){
                state = true
            }
            this.liveInfo = ''
            if( this.objLayerList[id] !== undefined && this.objLayerList[id].lay_field ){
                this.objLayerList[id].lay_field.forEach( elem => {
                    if( elem.infoPanel && info[ elem.source ] ){
                        this.liveInfo = (this.liveInfo.length > 0) ? this.liveInfo + " - " : this.liveInfo
                        this.liveInfo += info[ elem.source ]
                    }
                })
            }
            this.liveInfoState = state
            //this.liveInfo = info.nom_expert || info.CODIF
        },
        changeTileLayer(id){
            this.activeTile = id
            this.tileLayer[id].active = !this.tileLayer[id].active
            mapthis.map.setTileLayer(this.tileLayer[id], this.tileLayer[id].id)
        },
        setLayerStyle(id){
            mapthis.map.setTileStyle(this.tileLayer[id], this.tileLayer[id].id)
        },
        getLayerList(){
            return new Promise( (resolve, reject) => {
                var searchCriteria = '?page=1&per_page=false' // + this.pagination.itemsPerPage
                searchCriteria    += '&extended=true' //'&order_by=lay_name&order_direction=desc'
                this.$http.get( '/layers/' + searchCriteria ).then( (response) => {
                    response.data.data.forEach( (element) => {
                        element.loaded  = false
                        element.active  = false
                        element.edit    = false
                        try {
                            element.lay_field = JSON.parse(element.lay_field)
                        } catch {
                            element.lay_field = []
                        }
                    })
                    this.objLayerList = response.data.data
                    this.objLayerList.forEach( (element, i) => {
                        if( element.lay_sta_id == 'GEN01' ){
                            this.showHideSigLayer(i)
                        }
                    })
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        showHideSigLayer(id){
            if( this.objLayerList[id].loaded == false ){
                this.$http.get( '/layers/geojson/' + this.objLayerList[id].lay_id ).then( (response) => {
                        if( response.data.error !== true ){
                            mapthis.map.displayGeojson( response.data.data, id )
                            this.objLayerList[id].loaded = true
                            this.objLayerList[id].active = true
                            this.objLayerList[id].edit   = false
                            this.activeLayer[id]         = { active: true, edit:false }
                            mapthis.map.fitToTrackingSheet(id, this.objLayerList[id], this.lastSheet)
                        }
                })
            } else {
                this.objLayerList[id].active = !this.objLayerList[id].active
                this.showHideLayer( id, this.objLayerList[id].active )
            }
        },
        showHideLayer(id){
            if( this.activeLayer[id].active ){
                this.editLayer(id)
            }
            this.activeLayer[id].active = !this.activeLayer[id].active
            mapthis.map.showHideLayer( id, this.activeLayer[id].active, this.objLayerList[id] )
            mapthis.map.fitToTrackingSheet(id, this.objLayerList[id], this.lastSheet)
        },
        editLayer(id){
            mapthis.map.removePmControl(id)
            this.drawMode = false
            for (let i = 0; i < this.objLayerList.length; i++) {
                this.objLayerList[i].edit = false
            }
            if( this.activeLayer[id].active && !this.activeLayer[id].edit ){
                this.activeLayer[id].edit = !this.activeLayer[id].edit
                this.objLayerList[id].edit = !this.objLayerList[id].edit
                //mapthis.map.addPmControl(id)
                this.drawActiveId = id
                this.drawMode = true
            } else {
                this.activeLayer[id].edit = false
                this.objLayerList[id].edit = false
                this.drawActiveId = -1
            }
        },
        selectLayerColor(id){
            this.currentLayer     = id
            this.pickerColor      = this.hexToRgbA( this.objLayerList[ this.currentLayer ].lay_color )
            this.layerMenu        = false
            this.colorDialog      = true
        },
        saveLayerColor(color){
            var hexColor = "#" + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1)
            this.objLayerList[ this.currentLayer ].lay_color = hexColor
            mapthis.map.setLayerColor( this.currentLayer, hexColor )
            mapthis.map.setLayerTransparency( this.currentLayer, color.a )
        },
        fitTo(id){
            if( this.objLayerList[id].active ){
                this.layerMenu = false
                mapthis.map.fitToLayer( id )
            }
        },
        panTo(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                var coor = this.addressTab[ this.searchAddress ].item.geometry.coordinates
                mapthis.map.panTo( coor )
                mapthis.map.addCircleZone( coor )
            }
        },
        getTrackingList(){
            return new Promise( (resolve, reject) => {
                var searchCriteria = '?per_page=false'
                searchCriteria    += '&extended=true&trs_typ_id=SIT01,SIT02,SIT03'
                this.$http.get( '/trackingsheets/' + searchCriteria ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        response.data.data.forEach( (item) => {
                            if( item.trs_mc2_code ){
                                this.trackRefCode[ '' + item.trs_mc2_code ] = item
                            }
                            if( item.trs_mc2_id ){
                                this.trackRefCode[ '' + item.trs_mc2_id ] = item
                            }
                            if( item.trs_coddt ){
                                this.trackRefCode[ '' + item.trs_coddt ] = item
                            }
                        })
                        resolve('resolved')
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        hexToRgbA(hex){
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                a: 1
            } : null;
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label + ' (' + address.properties.postcode + ')', value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        getReport(){
            this.pdfloading = true
            let filename      = 'rapport.zip'
            let lay_id        = 0;
            this.objLayerList.forEach( (layer) => {
                if( layer.loaded ){
                    lay_id = layer.lay_id
                }
            })
            if( this.pdfOpt.type == 'Format Shape' ){
                this.$http.customRequest({ 
                    method: 'post', url: 'layers/shape/download', responseType: 'blob',
                    data: { lay_id: lay_id }
                }).then( (response) => {
                    this.$download(response.data, filename, response.headers['content-type'])
                    this.pdfloading = false
                }).catch( () => {
                    this.pdfloading = false
                })
            } else if( this.pdfOpt.type == 'Format Json' ){
                //getlayerDraw()
                this.$download('', filename, 'application/json')
            } else {
                LeafletImg(mapthis.map.Map, (err, canvas) => {
                    var img  = canvas.toDataURL();
                    let searchCriteria = this.getTrackingSheetFilter().substr(1)
                    let criteria = JSON.parse('{"' + searchCriteria.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
                    var form = { 
                        method: 'post', url: '/report/mapextraction/', responseType: 'blob', 
                        data: { trsfilter: criteria, tmpimage:img, pdfoption:this.pdfOpt }
                    }
                    this.$http.customRequest(form).then( (response) => {
                        this.pdfloading = false
                        this.pdfDiag = false
                        this.$download(response.data, filename, response.headers['content-type'])
                    })
                })  
            }
        },
        async saveDrawJson(){
            if( this.drawMode ){
                let geoJson = mapthis.map.getlayerDraw(this.drawActiveId)
                let lay_id   = this.objLayerList[this.drawActiveId].lay_id
                let lay_name = this.objLayerList[this.drawActiveId].lay_name
                this.$http.put( '/layers/geojson/' + lay_id, { lay_id: lay_id, lay_name: lay_name, geojson: geoJson } ).then( () => {
                    this.toast({ 
                        color: 'success', top:true, bottom:false, right:true, left:false, 
                        text: 'Modifications enregistrée !' 
                    })
                })
            }
        }
    },
    components: {
        'map-popup-content' : mapPopup,
        //draggable
    }
}
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
@import "../../node_modules/leaflet-linear-measurement/sass/Leaflet.LinearMeasurement.scss";
@import '../assets/leaflet.css';
</style>